function objectWithoutProperties (obj, exclude) { var target = {}; for (var k in obj) if (Object.prototype.hasOwnProperty.call(obj, k) && exclude.indexOf(k) === -1) target[k] = obj[k]; return target; }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.root},[(_vm.loading)?_c('VProgressLinear',{attrs:{"absolute":"","indeterminate":"","color":"primary darken-2"}}):_vm._e(),_c('div',{class:_vm.$style.row},[_vm._l((_vm.items),function(ref){
var id = ref.id;
var temp = ref.temp;
var date = ref.date;
var file = ref.file;
var value = ref.value;
var min = ref.min;
var rest = objectWithoutProperties( ref, ["id", "temp", "date", "file", "value", "min"] );
var item = rest;
return _c('div',{key:id || temp,class:_vm.$style.col},[_c('FileIcon',_vm._b({attrs:{"readonly":_vm.readonly || item.readonly},on:{"delete":function($event){return _vm.onDelete({ id: id, temp: temp, file: file })}}},'FileIcon',Object.assign({}, file),false),[(_vm.showCreateDate && file && file.date)?_c('div',{class:[_vm.$style.date, _vm.$style.readonly]},[_c('small',[_vm._v(_vm._s(file.date.slice(0, 10) || '__.__.____'))])]):(_vm.dated || item.dated)?_c('DateInput',{attrs:{"min":min,"value":date},on:{"input":function (date) { return _vm.onInput({id: id, temp: temp, value: value, date: date }); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var _obj, _obj$1;

var on = ref.on;
var formatted = ref.formatted;
var ISOString = ref.ISOString;return [(_vm.readonly || item.readonly)?_c('div',{class:[_vm.$style.date, _vm.$style.readonly, ( _obj = {}, _obj[_vm.$style.error] = (!formatted && (_vm.needDated || item.needDated)) || (min && (ISOString < min)), _obj )]},[_c('small',[_vm._v(_vm._s(formatted || '__.__.____'))]),(!formatted && (_vm.needDated || item.needDated))?_c('span',{class:_vm.$style.tooltip},[_vm._v("Дата обязательна")]):(min && (ISOString < min))?_c('span',{class:_vm.$style.tooltip},[_vm._v("Дата истекла")]):_vm._e()]):_c('div',{class:[_vm.$style.date, ( _obj$1 = {}, _obj$1[_vm.$style.error] = (!formatted && (_vm.needDated || item.needDated)) || (min && (ISOString < min)), _obj$1 )]},[_c('small',_vm._g({},on),[_vm._v(_vm._s(formatted || '__.__.____'))]),(!formatted && (_vm.needDated || item.needDated))?_c('span',{class:_vm.$style.tooltip},[_vm._v("Заполните дату")]):(min && (ISOString < min))?_c('span',{class:_vm.$style.tooltip},[_vm._v("Дата действия истекла")]):_vm._e()])]}}],null,true)}):_vm._e()],1)],1)}),(!_vm.readonly && !_vm.filled)?_c('div',{class:_vm.$style.col},[_c('label',[_c('div',{class:_vm.$style.input},[_c('VFileInput',{ref:"input",attrs:{"rules":_vm.checker,"multiple":_vm.multiple,"prepend-icon":null,"hide-details":"auto"},model:{value:(_vm.temp),callback:function ($$v) {_vm.temp=$$v},expression:"temp"}}),_c('FileIcon',{attrs:{"icon":"mdi-file-plus-outline"},scopedSlots:_vm._u([{key:"name",fn:function(){return [_vm._v(" "+_vm._s(_vm.addFileLabel)+" "),(_vm.required)?_c('span',{staticClass:"error--text"},[_vm._v("*")]):_vm._e()]},proxy:true}],null,false,4272486582)})],1)])]):_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }